// I suppose this is a hack?
// it makes sure that "100%" always includes horizontally scrolled content
// cp-bootstrap {
//     position: absolute;
//     min-width: 100%;
//     display: block;
// }


ngb-typeahead-window.dropdown-menu {
    max-height: 400px !important;
    overflow-y: auto !important;
    will-change: auto !important; // fixes a wierd blurring issue on chrome
}

// quick fix, fade transition with ngbmodal not working
.modal-backdrop.fade {
    transition: none;
}

.modal-open .cp-container {
    filter: blur(3px);
}

.main-content-container {
    padding-bottom: 150px;
}

.margin-bot {
    margin-bottom: 40px;
}

html, body {
    background-color: #fff;
    font-family: $font-normal;
    font-size: 0.95rem;
}

header h1 {
    font-size: 1.5rem;
    line-height: 4rem;
}

h2 {
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-weight: 600;

    margin-bottom: 1.5rem;
}

h3 {
    font-size: 1rem;
    font-weight: 600;
}

h4 {
    font-size: 0.95rem;
    font-weight: 600;
}


table.table thead th {
    z-index: 200;
    font-weight: 400;
    color: #aaa;
}

.table-hover tbody tr:hover {
    background-color: #f6f6f6;
}

.placement-timeline-list .table {
    background: transparent;
}

.table-responsive {
    overflow-x: unset;
}

.table-loader {
    z-index: 201;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.5);
}

.table-entity-list.table {
    thead {
        th {
            border-top: none;
            border-bottom: none;
        }
    }
}

.table tr {
    &.tr-first.tr-has-multiple td {
        padding-bottom: 0.2rem;
    }

    &.tr-not-first td {
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        border-top: none;
    }
}

.profile-dropdown-button.btn-link {
    color: rgba(255,255,255,0.5);

    &:hover, &.focus {
        color: rbga(255,255,255,0.75);
    }
}

// sets the tr rows of general siblings
// doesn't really work, should probably use javascript if I want this affect
// .table-hover tbody tr:hover ~ tr.tr-not-first {
//     // background-color: #f6f6f6;
// }

.progress {
    height: auto;
    font-size: 1rem;

    .progress-bar {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
}


.main-content-left {
    padding-left: 0.5rem;
    margin-right: -0.5rem;
}

.main-content {
    padding-left: 0.5rem;
}

.main-content-inner-min {
    min-width: 650px;
}

.max-width {
    max-width: 800px;
}

.bg-timeline {
    background-color: $bg-timeline !important;
}

.bg-timeline-past {
    background-color: $bg-timeline-past !important;
}

.bg-f6 {
    background-color: $bg-f6;
}

.card.card-form {
    &.alt-style {
        border-color: $input-border-color;

        .card-header {
            border-top: 1px solid $input-border-color;
            border-bottom: 1px solid $input-border-color;
        }
        
        .card-header:first-child {
            border-top: none;
        }
        
        .card-body {
            background-color: $bg-alt;
        }

        .card-header, .card-body {
            // margin-right: 1px; // hack for flex-tables used inside this card
            margin-right: 1px;
        }
    }
}

.bg-warning-light { background-color: $bg-warning-light !important; }
.bg-info-light { background-color: $bg-info-light !important; }
.bg-danger-light { background-color: $bg-danger-light !important; }
.bg-success-light { background-color: $bg-success-light !important; }
.bg-primary-light { background-color: $bg-primary-light !important; }

.text-black {
    color: $black;
}

.w-0 {
    width: 0;
}
.mw-0 {
    max-width: 0;
}

.shadow-inset {
    box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.3) inset !important;
}

.overflow-hidden {
    overflow: hidden;
}

/* support for 7-width cols  14.285714285714285714285714285714 */

.col-xs-7r,
.col-sm-7r,
.col-md-7r,
.col-lg-7r {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-7r {
    width: 14.285714285714285714285714285714%;
    float: left;
}

@media (min-width: 768px) {
    .col-sm-7r {
        width: 14.285714285714285714285714285714%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-7r {
        width: 14.285714285714285714285714285714%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-7r {
        width: 14.285714285714285714285714285714%;
        float: left;
    }
}


/* support for 8-width cols */

.col-xs-8r,
.col-sm-8r,
.col-md-8r,
.col-lg-8r {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col-xs-8r {
    width: 12.5%;
    float: left;
}

@media (min-width: 768px) {
    .col-sm-8r {
        width: 12.5%;
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-8r {
        width: 12.5%;
        float: left;
    }
}

@media (min-width: 1200px) {
    .col-lg-8r {
        width: 12.5%;
        float: left;
    }
}

.text-red {
    color: #F00 !important;
}

.text-pre-wrap {
    white-space: pre-wrap;
}

.text-decoration-none {
    text-decoration: none !important;
}

.border-transparent {
    border-color: transparent !important;
}

.border-input {
    border-color: $input-border-color !important;
}

.border-left-dashed {
    border-left: $border-width $border-color dashed !important;
}

form, .form-style {
    
    .form-control {
        border-color: $input-border-color;
    }

    &.alt-style {
        background-color: $bg-alt;
        border: 1px solid $input-border-color;
    }
}

.input-min-width {
    min-width: 200px;
}

.form-control-plaintext.form-control:disabled, 
.form-control-plaintext.form-control[readonly] {
    background-color: transparent;
}

.badge {
    text-transform: none;
}

// creates an outline badge type for each color
@each $color, $value in $theme-colors {
    .badge-outline-#{$color} {
        @include badge-variant($value);
        background: transparent;
        border-color: $value;
        border-style: solid;
        border-width: 0.05rem;
    }
}

.badge-list-max-width {
    max-width: 50px;
}

.text-shadow {
    text-shadow: 0 0 4px rgba(0,0,0,0.5);
}

.text-large {
    font-size: 1.2rem;
}

.ball {
    height: 10px;
    width: 10px;
    border-radius: 50%;
}

.fs-70 {
    font-size: 70%;
}

//toggle disabled override
.toggle-disabled .btn {
    color: $gray-600 !important;
}


.custom-calender-icon-btn.disabled .custom-calender-icon {
    color: $gray-400;
    cursor: inherit;
}

// VALIDATION STYLES 
.input-validation-container {
    opacity: 0;
    transition: $transition-fade;
}

@mixin form-validation-styles() {
    .cp-inner-form-control.form-control:not(:focus),
    .cp-inner-form-control:not(.focused) {
        color: $danger;
        border-color: $danger;
        background-color: $bg-input-danger;

        transition: background-color .15s ease-in-out
                    $input-transition;
    }

    .input-validation-container {
        opacity: 1;
    }
}

// NOTE: .ng-invalid is applied to the parent container (cp-input) element, not the inner actual input component...
// or, the array container (cp-form-control-array)
.cp-form-control.ng-invalid.ng-touched {
    @include form-validation-styles();
}

.cp-form-control-array.ng-invalid.ng-touched {
    .cp-form-control.ng-invalid {
        @include form-validation-styles();
    }
}

// BUTTONS
// TODO - use bootstrap variables for these values
.btn-link-fake {
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border: 0.0625rem solid transparent;
    line-height: 1.5;
    padding: 0.44rem 0.75rem;

    &.fake-dropdown {
        margin-left: 0.05em;
        margin-bottom: 0.05em;
    }
}

// this is semi overridding the chubby styles
.btn-sm {
    @include button-size($btn-padding-y-sm, 0.2rem, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
}


.btn-secondary, .btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active, .btn-secondary.active {
    color: #fff;
}

.btn-extend {
    padding-left: 1.8rem;
    padding-right: 1.8rem;
}

.btn {
    border-width: 0.0625rem;
}

.btn.btn-sm[class*='btn-outline'] {
    padding: 0.25rem 0.4375rem;
}

.btn[class*='btn-'] {
    padding-top: 0.44rem;
    padding-bottom: 0.44rem;
}

.btn-link:focus.btn-no-focus, .btn-link.focus.btn-no-focus {
    text-decoration: none;

    &:hover, &.hover {
        text-decoration: underline;
    }
}

.btn.btn-no-focus {
    box-shadow: none;
}

.btn-input {
    @include button-variant($input-border-color, $input-border-color);
}

.btn-outline-input {
    @include button-outline-variant($input-border-color);
}

.fat-span {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.cursor {
    cursor: pointer;
}

/* used for the calendar icon with NgbDatepicker Module */
.btn-input-append {
    width: 1.2rem; 
    height: 1rem; 
    cursor: pointer;
}

.btn-block-primary {
    background-color: $block-primary;
    border: 1px solid darken($block-primary, 20%);
}

.btn-block-secondary {
    background-color: $block-secondary;
    border: 1px solid darken($block-secondary, 20%);
}

.btn-block-filler {
    background-color: $block-filler;
    border: 1px solid darken($block-filler, 20%);
}

.color-dark {
    color: #000 !important;
}

.color-light {
    color: #fff !important;
}

.date-range-day-selector {
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.minutes-summary {
    width: 200px;
}

// TODO: remove
.loader {
    background: url('/assets/loader.svg') transparent center no-repeat;

    &.loader-large {
        height: 200px;
    }

    &.loader-small {
        height: 50px;
        // background-size: 50%;
    }

    &.loader-bar {
        min-width: 130px;
        height: 20px;
        background: url('/assets/loader-bar.svg') transparent left no-repeat;
        opacity: 0.4;
    }
    
    &.loader-none {
        background-image: none;
    }
}

@include keyframes(fadein) {
    0% { opacity: 0; }
    20% { opacity: 0; }
    100%   { opacity: 1; }
}

.fade-in {
    @include animation('fadein 2s');
}


.alert {
    // override chubby
    margin-bottom: 0;

    &.alert-sm {
        padding: 0.25rem 0.5rem;
        font-size: 80%;

        .alert-body:not(:first-child) {
            margin-left: 0.5rem;
        }
    }
}

// text overrides
.alert.alert-warning {
    
    .text-warning {
        color: #a77d02 !important;

        &:hover {
            color: darken(#a77d02, 10%) !important;
        }
    }
}

.alert-oi-warning {
    font-size: 1.9rem;
}

// Navigation
.navbar .cp-logo {
    padding-left: 0.5em;
    margin-bottom: 1rem;

    svg {
        max-height: 80px;
        max-width: 150px;
    }
}

.navbar-dark .cp-logo {
    fill: $navbar-dark-color;

    &:hover {
        fill: $navbar-dark-hover-color;
    }
}

.navbar-light .cp-logo {
    fill: $navbar-light-color;

    &:hover {
        fill: $navbar-light-hover-color;
    }
}

.nav-item.dropright .dropdown-toggle::after {
    margin-left: 1rem;
}

.code-custom {
    color: #212529;
    font-weight: bold;
}

.entity-details-inner-container:last-child .property-display:last-child {
    border-bottom: none !important;
}

.property-display-label {
    line-height: 1.1rem;   
}

.property-display-margin {
    margin-bottom: 0.2rem;
    padding-bottom: 0.6rem;
    padding-top: 0.2rem;
}

.bg-input {
    background-color: $input-bg;
}

// LISTS

.list-group.list-group-flush {

    .list-group-item-flex-fix {
        margin-top: 1px;
    }
    
    .list-group-item:first-child {
        border-top: none;
    }

    .list-group-item:last-child {
        border-bottom: none;
    }

    .list-group-item-action {
        padding-left: 1rem;
        transition: 
            background-color .15s ease-in-out,
            padding-left .15s ease-in-out;

        // &:hover {
        //     padding-left: 1.5rem;
        // }
    }
}

.striped-light {
    background: repeating-linear-gradient(
        -55deg,
        rgba(68, 68, 68, 0.05),
        rgba(68, 68, 68, 0.05) 10px,
        rgba(102, 102, 102, 0.05) 10px,
        rgba(102, 102, 102, 0.05) 20px
    );
}

.page-divider {
    height: 1px;
    background-color: $bg-timeline;
}

.dropdown-menu {
    margin-bottom: 200px;
}

.cp-typeahead-container .dropdown-menu {
    min-width: 100%;

    .dropdown-item.active {
        background-color: #ececec;
        color: #000;
    }
}


// TOKENFIELD OVERRIDES
.tokenfield-wrapper {
    
    border: $input-border-width solid $input-border-color;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: $white;
    // border-right: none;

    // Note to the note below (this was taken directly from the bootstrap scss files for forms
    // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
    @if $enable-rounded {
        // Manually use the if/else instead of the mixin to account for iOS override
        border-top-left-radius: $input-border-radius;
        border-bottom-left-radius: $input-border-radius;
    } @else {
        // Otherwise undo the iOS default
        border-radius: 0;
    }

    &.focused {
        // these are all stolen straight out of bootstrap's mixins
        // but the "tokenfield" library insists on using inline styles
        // and i need to override them somehow...
        color: $input-focus-color  !important;
        background-color: $input-focus-bg  !important;
        border-color: $input-focus-border-color  !important;
        outline: 0;
        // Avoid using mixin so we can pass custom focus shadow properly
        @if $enable-shadows {
            box-shadow: $input-box-shadow, $input-focus-box-shadow  !important;
        } @else {
            box-shadow: $input-focus-box-shadow  !important;
        }
    }

    .tokenfield {
        // this is so gross
        &.tokenfield-mode-tokens {
            border: none !important;
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            min-height: 35px !important;
            background-color: inherit !important;
        }

        .tokenfield-input {
            background-color: inherit !important;
        }

        .tokenfield-suggest {
            z-index: 300;
        }
    }
}

.tokenfield-dropdown {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-color: $input-border-color;

    .oi {
        top: 3px;
    }
}

.dropdown-max-height {
    max-height: 400px;
    overflow-y: auto;
}



.pagination-container {
    .oi {
        font-size: 0.8rem;
    }
}

.oi.oi-sm {
    font-size: 0.6rem;
}


// SPECIFIC STYLES
.bg-transition {
    transition: background-color 0.2s ease-in-out;
}

// PLACEMENT LIST GROUPS

.placement-list-group-container {

    &.has-selected .placement-list-group-item {
        opacity: 0.4;
    }

    .placement-list-group-item-inner {
        padding: 0.75rem 1.25rem;
    }
}

// Modal overrides
.modal.full-modal {

    .modal-dialog {
        width: 100%;
        max-width: inherit;
    }
    
    .modal-content {
        padding: 0 1.75rem;
        background-color: inherit;
        border: none;
        border-radius: 0;
    }
}

// position match
.constraint-match-list-group {
    .list-group-item {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    .list-group-item.action:active {
        background-color: inherit;
    }
}

.position-match-filter-center {
    width: 40px;
    position: absolute;
    left: 50%;
    margin-left: -20px;
}


// these names are stupid.
.opacity-0 {
    opacity: 0 !important;
}
.opacity-1 {
    opacity: 1 !important;
}

.opacity-33 {
    opacity: 0.33333 !important;
}
.opacity-5 {
    opacity: 0.5 !important;
}

// todo: move this and all timesheet styles to the same place
.ts-week-date {
    font-size: 1.5em;
    padding-right: 0.5rem;
}
.ts-edge-column {
    width: 80px;
}
.bg-ts-attended {
    background-color: $bg-ts-attended;
}

.bg-ts-not-attended {
    background-color: $bg-ts-not-attended;
}

.modal-failed {
    -moz-box-shadow: inset 0 0 10px #ead2d6;
    -webkit-box-shadow: inset 0 0 10px #ead2d6;
    box-shadow: inset 0 0 20px #ead2d6;
    border: 1px solid $danger;
}

.text-strike {
    text-decoration: line-through;
}

// ALERT BOOTSTRAP OVERRIDE
.alert-text, 
.alert-link,
.alert.alert-success,
.alert.alert-warning, 
.alert.alert-info, 
.alert.alert-danger,
.alert.alert-primary {
    color: #1e1e1e;

    .alert-text, 
    .alert-link {
        color: #1e1e1e;
    }
}

.alert.alert-warning { background-color: $bg-warning-light; }
.alert.alert-info { background-color: $bg-info-light; }
.alert.alert-danger { background-color: $bg-danger-light; }
.alert.alert-success { background-color: $bg-success-light; }
.alert.alert-primary { background-color: $bg-primary-light; }

// .alert.alert-danger fa-icon { color: $danger; }
// .alert.alert-info fa-icon { color: $info; }
// .alert.alert-warning fa-icon { color: $warning; }

// mini-profiler link fix
.mp-results a:not([href]):not([tabindex]) { color: #07c; }

// date range indicator
.date-range-indicator {
    background-color: #f6f6f6;
    color: rgba(0,0,0,0.6);

    .date-range-indicator-inner {
        background-color: $block-primary; // $primary; // $block-primary;
        padding: 0.1rem 0.3rem;

        &:hover {
            background-color: lighten($block-primary, 5%);
            cursor: pointer;
        }

        &.is-after-start:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 20px;
            height: 100%;
            border-left: 1px solid #dee2e6
        }
        
        &.is-before-end:after {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            width: 20px;
            height: 100%;
            border-right: 1px solid #dee2e6;
        }

        .date-range-indicator-break {
            position: absolute;
            top: 0;
            height: 100%;
        }
    }
}

.breadcrumb-item a {
    color: rgba(255,255,255,0.5);

    &:hover {
        color: rgba(255,255,255,0.75);
    }
}

// STYLES THAT GET OVERWRITTEN IN PRINT

// wrapped here because i override in print styles
.filter-path-condition {
    // mt-1 px-2 pb-2 border rounded bg-f6 
    margin-top: ($spacer * .25);
    padding: 0 ($spacer * .5) ($spacer * .5);
    border-radius: $border-radius;
    border: $border-width solid $border-color;
    background-color: $bg-f6;
}

.filter-input-inner {
    // p-2 rounded bg-input
    padding: ($spacer * .5);
    border-radius: $border-radius;
    background-color: $input-bg
}