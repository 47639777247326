html {
    body {
        font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

        pre,
        code,
        kbd,
        samp {
            font-family: 'SFMono-Regular', Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
        }

        h1,
        .h1,
        h2,
        .h2,
        h3,
        .h3,
        h4,
        .h4,
        h5,
        .h5,
        h6,
        .h6 {
            color: #222;
            font-weight: 500;
            line-height: 1.17;
        }

        h1,
        .h1 {
            font-size: 1.4375rem;
        }

        h2,
        .h2 {
            font-size: 1.3125rem;
        }

        h3,
        .h3 {
            font-size: 1.125rem;
        }

        h4,
        .h4 {
            font-size: 1rem;
        }

        h5,
        .h5 {
            font-size: 0.9375rem;
            font-weight: bold;
        }

        h6,
        .h6 {
            font-size: 0.875rem;
            font-weight: bold;
        }
    }
}