@media print {
    @page {
        size: A4 landscape;
        max-height: 100%;
        max-width: 100%;
    }

    html, body {
        width: 100%; 
        height: 100%;
        margin: 0;
        padding: 0;
    }

    .main-content-container {
        padding-bottom: 0px;
    }

    .filter-path-condition {
        padding: 0;
        background-color: transparent;
        border: none;
    }

    .filter-input-inner {
        padding: 0;
        background-color: transparent;
        border: none;
    }

    .table {
        th,
        td {
          padding: $table-cell-padding-sm;
        }
    }

    table.table thead th {
        color: #000;
    }

    .mp-results {
        display: none;
    }

    // dmu specific - for now
    .print-banner svg {
        height: 80px;
        margin-left: 1rem;
    }
}