$alert-bg-level: 0;
$alert-border-level: 1.5;
$theme-color-interval: 8;
$alert-color-level: -100 / $theme-color-interval; //100% lighten - white


.alert {
    display: flex;
    font-size: 0.8125rem;
    text-shadow: none;

    .fas {
        font-size: 1.1rem;
        margin-top: 0.1875rem;
    }

    @each $color,
    $value in $theme-colors {
        &.alert-#{$color} {
            border-color: mix($black, $value, 11%);
            padding: 0.75rem 1rem;

            .alert-link {
                color: $white;
            }

            .alert-body {
                margin-top: 0.125rem;

                &:not(:first-child) {
                    margin-left: 1rem;
                }

                &:not(:last-child) {
                    margin-right: 1rem;
                }
            }

            .btn {
                background: fade-out($black, 0.8);
                border: mix($black, $value, 20%) solid 0.0625rem;
                color: $white;
                font-size: 0.8125rem;
                padding: 0.3125rem 0.75rem;

                @include media-breakpoint-up(md) {
                    margin: -0.375rem 0;
                }

                @include media-breakpoint-down(sm) {
                    margin: 0.375rem 0;
                }

                &:hover {
                    background: fade-out($black, 0.6);
                }
            }
        }
    }

    strong {
        font-size: 0.875rem;
    }

    &.alert-link {
        color: $black;
    }

    &.alert-dismissible {
        .close {
            color: $black;
            opacity: 0.3;
        }
    }

    .close {
        opacity: 0.3;
        text-shadow: none;

        .fas {
            font-size: 1.2rem;
        }
    }

    &.alert-block {
        border-radius: 0;
        border-width: 0;
        margin: 0;
    }
}
