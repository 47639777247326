@import '~styles/cp-variables';
@import '~styles/cp-mixins';

/*
  timeline really specific variables
*/

$tl-initial-min-height: 25px;
// this needs to be set if we want to be able to drag.
//width: 200%; 
$tl-initial-width: 100%;
$tl-initial-first-row-position: 65px;

//These classes are used to position something within a week block
.tl-left7-0 { left: 0% }
.tl-left7-1 { left: 14.28571428571429% }
.tl-left7-2 { left: 28.57142857142858% }
.tl-left7-3 { left: 42.85714285714287% }
.tl-left7-4 { left: 57.14285714285716% }
.tl-left7-5 { left: 71.42857142857145% }
.tl-left7-6 { left: 85.71428571428574% }

.timeline-container {
    width: $tl-initial-width;
}

.timeline-loader {
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 120;
    background-color: rgba(0,0,0,0.2);

    min-height: $tl-initial-min-height;
}

.timeline-inner {
   //height: $tl-initial-height;
   min-height: $tl-initial-min-height;
   width: $tl-initial-width;

   background: $bg-timeline;

   position: relative;
   overflow: hidden;
}

.tl-week-container {
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    
    background: linear-gradient(to right,
        $border-color,
        $border-color $border-width,
        rgba(255,255,255,0) $border-width,
        rgba(255,255,255,0)
    );

    border-top: $border-width solid $border-color;
}

.tl-week {
    display: block;
    height: 100%;

    position: absolute;
    white-space: nowrap;

    //note: this has to be here otherwise the border px will screw with all the width ratios
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .tl-week-date {
        display: block;
        position: absolute;
        top: 0;
        // margin-top: -40px;
        transform: rotate(-45deg);
        color: $tl-font-color;
    }
}

.tl-all-years-container,
.tl-all-months-container {
    position: relative;
    z-index: 100;
    height: 30px;
}

.tl-week-year-text {
    font-size: 1.2em;
}

.tl-week-month {
    display: flex;
    position: absolute;
    top: 0;
    // margin-top: -30px;
    height: 30px; //temp
    padding-left: 10px;
    // text-align: center;
    margin-left: -1px;
    text-transform: uppercase;
    border-left: 1px solid $tl-month-border;
    color: $tl-font-color;
    overflow: hidden;

    .tl-week-month-text {
       padding-top: 5px;

       &:last {
            display: none;
        }
    }
}

.tl-block {
    position: absolute;
    height: 60px;
    z-index: 100;
    border-radius: 2px;
    //overflow: hidden;

    text-align: center;
    font-weight: bold;
    // text-transform: uppercase;

    border: 1px solid black;

    border-radius: 4px;

    &:hover {
        @include boxShadow(rgba(0, 0, 0, 0.4), 4px, 2px);
    }

    &:hover {
        cursor: pointer;
    }

    &.tl-block-content {
        z-index: 106;
        overflow: hidden;

        font-weight: normal;
        font-size: 75%;

        overflow: hidden;
    }

    &.tl-active-past {
        background-color: rgba(0,0,0, 0.15);
    }

    &.tl-block-active-curtain {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        z-index: 101;
    }

    .tl-block-content-container {
        overflow: hidden;
        margin: 5px;
    }
        
    &.selected {
        z-index: 103;

        &.tl-block-content {
            z-index: 104;
        }
    }

    &.tl-block-bg {
        border: 1px solid #f1f1f1;
    }
}

// breaks simple
.tl-block-break {
    position: absolute;
    z-index: 104;
    background-color: rgba(255, 255, 255, 0.8);
    border-left: 1px dashed black;
    border-right: 1px dashed black;
}

//has-selected
.timeline-container.has-selected {
    .tl-block.tl-block-bg-main {
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            // background-color: rgba(220,220,220, 0.7);
        }

        &.selected:before {
            content: none;
        }

        &.selected {
            box-shadow: 0 0 0.3rem rgba(0, 0, 0, 1);
        }
    }
    .tl-block:not(.selected) {
        opacity: 0.45;
        border-color: rgba(0,0,0,0.3);

        // overrides color-light to cater for massive opacity hit
        &.tl-block-content.color-light {
            color: #000 !important;
        }
    }
}


.tl-first-week {

    /* should only apply to the first block of the "Week" */
    .tl-block.tl-left7-0 {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

// Everything below is new sytles on the new system... everything above should be changed to what is it below.

.tl-all-container {
    position: relative;
    top: 0;
    width: 100%;
    height: 100%;
    // padding-bottom: 20px;
    padding-top: 25px;
}

.tl-plan-container, 
.tl-student-placements-container {
    position: relative;
    z-index: 110;
}

.tl-data-container {
    padding-top: 5px;
    padding-bottom: 5px;
}

.tl-plan-title {
    padding-top: 20px;
    padding-left: 15px;
    background-color: #fff;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;
}

.tl-all-placement-container {
    padding-top: 25px;
    padding-bottom: 25px;
}

.tl-placement-container {
    position: relative;
    margin-top:5px;
}

.tl-week-total-container {
    position: relative;
    height: 20px;
}

.tl-week-total {
    position: absolute;
    top: 30px;
    font-weight: bold;
    font-size: 1.2rem;
    padding-left: 0.5rem;
    border-top: 1px solid #ccc;
}

.tl-data-item-container {
    z-index: 201;
    position: relative;
    overflow-x: hidden;

    &:before {
        content: '';
        height: 1px;
        position: absolute;
        z-index: 220;
        width: 100%;
        background-color: $tl-week-border;
        margin-top: -1px;
    }

    background: linear-gradient(to right,
        $border-color,
        $border-color $border-width,
        rgba(255,255,255,0) $border-width,
        rgba(255,255,255,0)
    );
}

.tl-column-header-th {
    padding: 0 !important;
    top: 0 !important;
    position: relative !important;
    border-top: 0 !important;

    // bad hack
    &.tl-show-week-height-fix .timeline-inner {
        min-height: 65px;
    }
}

.tl-week-background-curtain {
    position: absolute;
    width: 100%;
    border-left: 1px solid transparent;
    opacity: 0.5
}

.tl-past {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.05);
    height: 100%;
}

.tl-today {
    border-right: 1px solid $tl-today-color;
}