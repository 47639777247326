$pagination-color: #999;
$pagination-border-color: #e4e4e4;

$pagination-padding-y: 0.8rem;
$pagination-padding-x: 1.1rem;

$pagination-hover-color: $body-color;
$pagination-hover-bg: #dbeff8;

$pagination-focus-outline: 0;

$pagination-disabled-color: $dark;
$pagination-disabled-bg: #f5f5f5;
$pagination-disabled-border-color: #e4e4e4;

$pagination-active-border-color: $pagination-border-color;

.pagination .page-item .page-link {
    &:hover {
        z-index: 0;
    }

    &:focus {
        box-shadow: 0 0 0 $black;
        outline: none;
    }
}
